<script setup>
import { computed, ref, watch } from 'vue'
import { Icon } from '@iconify/vue'
import moment from 'moment'
import Modal from '../atoms/Modal.vue'
import Button from '../atoms/Button.vue'
import VoucherSelection from './VoucherSelection.vue'
import PaymentMethodSelection from './PaymentMethodSelection.vue'
import useAppData from '@/composables/useAppData'
import { rupiah } from '@/utils/helpers'
import Api from '@/utils/api'
import { useGlobalStore } from '@/store/global'

const emit = defineEmits(['success'])

const { logoSquare } = useAppData()
const globalStore = useGlobalStore()

const isOpen = ref(false)
const currentStep = ref(0)
const selectedPaymentMethod = ref(null)
const selectedVoucher = ref(null)
const voucherDiscount = ref(0)

const product = ref({
  product_id: null,
  product_name: null,
  price: null,
  sale_price: null,
  subscription_duration: null,
  quantity: 1,
})

const referral = ref({
  code: '',
  loading: false,
  discount: null,
  isError: false,
})

const totalPayment = computed(() => {
  return product.value.sale_price - (referral.value?.discount ?? 0) - (voucherDiscount.value ?? 0)
})

const platformFee = computed(() => {
  if (selectedPaymentMethod.value?.charge_type === 'PERCENT')
    return totalPayment.value * selectedPaymentMethod.value.charge_amount / 100
  else
    return selectedPaymentMethod.value?.charge_amount ?? 0
})

const subscriptionExpiryDate = computed(() => {
  if (product.value?.subscription_duration === null || product.value?.subscription_duration === undefined)
    return null

  return moment().add(product.value?.subscription_duration, 'days').format('DD/MM/YYYY')
})

const isFormValid = computed(() => {
  if (!selectedPaymentMethod.value)
    return false

  return true
})

function reset() {
  currentStep.value = 0
  selectedPaymentMethod.value = null
  selectedVoucher.value = null
  voucherDiscount.value = 0

  product.value = {
    product_id: null,
    product_name: null,
    price: null,
    sale_price: null,
    subscription_duration: null,
  }

  referral.value = {
    code: '',
    loading: false,
    discount: null,
    isError: false,
  }
}

function onSelectMethod(item) {
  selectedPaymentMethod.value = item

  currentStep.value = 1
}

function onSelectVoucher(voucher) {
  selectedVoucher.value = voucher

  if (voucher) {
    if (voucher.rules.type === 'percent')
      voucherDiscount.value = product.value.sale_price * +voucher.rules.discount / 100
    else
      voucherDiscount.value = +voucher.rules.discount
  }
}

async function onApplyReferral() {
  referral.value.discount = 0
  referral.value.isError = false

  try {
    referral.value.loading = true

    const api = await Api.post('/verify-referral-code', {
      code: referral.value.code,
      product_id: product.value.product_id,
    })

    referral.value.discount = api.data.discount
    referral.value.loading = false
    referral.value.isError = false
  }
  catch (err) {
    referral.value.isError = true
    referral.value.loading = false
  }
}

async function onPayment() {
  try {
    globalStore.isLoading = true

    const api = await Api.post('/create-invoice', {
      product_id: product.value.product_id,
      payment_method: selectedPaymentMethod.value.name,
      affiliate_code: referral.value.code,
      voucher_code: selectedVoucher.value?.code ?? null,
      total_payment: totalPayment.value + platformFee.value,
      quantity: product.value.quantity,
    })

    window.open(api.data.payment_url, '_blank')

    globalStore.isLoading = false
    isOpen.value = false
    emit('success')
    reset()
  }
  catch (err) {
    console.log(err)

    globalStore.isLoading = false
  }
}

function show(productData, additionalData = {
  step: 0,
  voucher: undefined,
}) {
  if (additionalData.step) {
    currentStep.value = additionalData.step
  }

  if (additionalData.voucher) {
    onSelectVoucher(additionalData.voucher)
  }

  product.value = productData
  isOpen.value = true
}

watch(currentStep, () => {
  if (currentStep.value === 0) {
    referral.value.isError = false
  }
})

defineExpose({ show })
</script>

<template>
  <Modal
    v-model="isOpen"
    no-padding
    max-width="500px"
    @close="reset"
  >
    <div class="flex flex-col">
      <div class="p-3 m-2 bg-primary-lighten rounded">
        <div class="flex justify-between items-center mb-2">
          <h6 class="text-black-800 font-medium">
            Pembayaran
          </h6>
          <Icon
            icon="ri:close-line"
            class="text-[28px] cursor-pointer text-black-700"
            @click="isOpen = false"
          />
        </div>

        <span class="block text-caption font-medium text-primary leading-none">
          {{ product.product_name }}
        </span>
        <span v-if="subscriptionExpiryDate" class="text-small text-black-600 leading-none">
          Berlaku Hingga {{ subscriptionExpiryDate }}
        </span>
      </div>

      <div class="flex flex-col overflow-y-auto overflow-x-hidden px-3 pb-4 max-h-[65vh] min-h-[65vh]">
        <Transition name="slide-x-transition" mode="out-in">
          <div v-if="currentStep === 0">
            <h6 class="font-medium my-4">
              Pilih Metode Pembayaran
            </h6>

            <PaymentMethodSelection
              @on-select-method="onSelectMethod"
            />
          </div>

          <div v-else-if="currentStep === 1" class="flex flex-col gap-4 flex-1 mt-4">
            <div
              class="flex items-center border rounded p-2.5 cursor-pointer"
              :class="{
                'border-danger': !selectedPaymentMethod,
              }"
              @click="currentStep = 0"
            >
              <template v-if="selectedPaymentMethod">
                <img
                  class="w-8 h-8 object-contain rounded mr-2"
                  :src="selectedPaymentMethod?.icon ?? logoSquare"
                >
                <div>
                  <p class="text-small text-black-600">
                    Metode Pembayaran
                  </p>
                  <h6 class="text-small font-medium text-primary">
                    {{ selectedPaymentMethod?.name }}
                  </h6>
                </div>
                <p class="text-tiny text-black-600 ml-auto">
                  Klik Untuk Ubah
                </p>
              </template>

              <template v-else>
                <div>
                  <p class="text-small text-danger opacity-75">
                    Metode Pembayaran Belum Dipilih
                  </p>
                  <h6 class="text-small font-medium text-danger">
                    Klik untuk memilih metode pembayaran
                  </h6>
                </div>
              </template>
            </div>

            <div
              class="border rounded p-2.5 cursor-pointer"
              @click="currentStep = 2"
            >
              <div class="flex items-center">
                <div>
                  <h6 class="text-small font-medium text-primary">
                    Voucher Promo
                  </h6>
                  <p class="text-small text-black-600">
                    Pilih Voucher Disini
                  </p>
                </div>
                <Icon
                  class="text-[24px] text-black-600 ml-auto"
                  icon="ri:arrow-right-s-line"
                />
              </div>

              <div
                v-if="selectedVoucher"
                class="flex justify-between items-center bg-primary-lighten text-small p-2.5 rounded mt-2"
              >
                <div class="flex flex-col">
                  <span class="text-tiny text-black-600 mb-0.5">
                    Promo Digunakan
                  </span>
                  <span class="text-primary font-medium">
                    {{ selectedVoucher?.description }}
                  </span>
                </div>

                <h6
                  class="font-medium text-small text-danger cursor-pointer"
                  @click.stop="selectedVoucher = null; voucherDiscount = 0"
                >
                  Hapus
                </h6>
              </div>
            </div>

            <div>
              <h6 class="text-small font-medium mb-2">
                Kode Affiliate (Jika Ada)
              </h6>

              <div class="flex items-center gap-2">
                <input
                  id="code"
                  v-model="referral.code" type="text"
                  class="transition-all bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Input Kode Affiliate"
                >

                <div
                  class="h-[42px] w-[120px] rounded text-caption flex items-center justify-center"
                  :class="{
                    'bg-primary text-white cursor-pointer': referral.code?.length > 3,
                    'bg-black-400 text-black-600 cursor-not-allowed': !(referral.code?.length > 3),
                  }"
                  @click="referral.code?.length > 3 && !referral.loading ? onApplyReferral() : null"
                >
                  <template v-if="referral.loading">
                    <Icon
                      class="text-white text-[20px]"
                      icon="svg-spinners:ring-resize"
                    />
                  </template>

                  <template v-else>
                    Apply
                  </template>
                </div>
              </div>

              <Transition name="scroll-y-transition" mode="out-in">
                <div
                  v-if="referral.isError || referral.discount !== null"
                  class="p-2 rounded text-small mt-2"
                  :class="{
                    'bg-danger-lighten text-danger': referral.isError,
                    'bg-success-lighten text-success': !referral.isError,
                  }"
                >
                  <template v-if="referral.isError">
                    Kode Tidak Valid
                  </template>
                  <template v-else>
                    Berhasil mendapatkan diskon {{ rupiah(referral.discount) }}
                  </template>
                </div>
              </Transition>
            </div>

            <div class="mt-auto flex flex-col gap-1">
              <h6 class="text-caption font-medium">
                Ringkasan Pembayaran
              </h6>

              <div class="flex justify-between text-caption">
                <span class="text-black-600">
                  {{ product.product_name }}
                </span>
                <span class="font-medium">
                  {{ rupiah(product.price) }}
                </span>
              </div>

              <div class="flex justify-between text-caption">
                <span class="text-black-600">
                  Diskon
                </span>
                <span class="font-medium">
                  - {{ rupiah(product.price - product.sale_price) }}
                </span>
              </div>

              <div v-if="referral.discount" class="flex justify-between text-caption">
                <span class="text-black-600">
                  Potongan Kode Afiliate
                </span>
                <span class="font-medium">
                  - {{ rupiah(referral.discount) }}
                </span>
              </div>

              <div v-if="voucherDiscount" class="flex justify-between text-caption">
                <span class="text-black-600">
                  Potongan Voucher
                </span>
                <span class="font-medium">
                  - {{ rupiah(voucherDiscount) }}
                </span>
              </div>

              <div class="flex justify-between text-caption">
                <span class="text-black-600">
                  Platform Fee
                </span>
                <span class="font-medium">
                  {{ rupiah(platformFee) }}
                </span>
              </div>
            </div>

            <div class="flex items-center">
              <div class="flex flex-col">
                <small class="text-tiny text-black-600">
                  Total Bayar
                </small>
                <h6 class="text-primary font-semibold">
                  {{ rupiah(totalPayment + platformFee) }}
                </h6>
              </div>

              <Button
                :disabled="!isFormValid"
                class="ml-auto py-2 px-12"
                @click="onPayment"
              >
                Bayar
              </Button>
            </div>
          </div>

          <div v-else-if="currentStep === 2">
            <div class="flex items-center gap-2 my-4">
              <Icon
                class="text-[24px] cursor-pointer"
                icon="ri:arrow-left-line"
                @click="currentStep = 1"
              />
              <h6 class="font-medium">
                Voucher
              </h6>
            </div>

            <VoucherSelection
              :selected-voucher="selectedVoucher?.id"
              :payment-price="product.sale_price"
              @on-voucher-select="onSelectVoucher($event); currentStep = 1"
            />
          </div>
        </Transition>
      </div>
    </div>
  </Modal>
</template>
