<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getActivePinia } from 'pinia'
import { useToast } from 'vue-toastification'
import { useGlobalStore } from '@/store/global'
import { useAuthStore } from '@/store/auth'
import useAppData from '@/composables/useAppData'
import Api from '@/utils/api'
import ProductsModal from '@/components/molecules/ProductsModal.vue'

const { currentRoute } = useRouter()
const authStore = useAuthStore()
const { desktopMenu, appId, logoExpand } = useAppData()
const globalStore = useGlobalStore()
const toast = useToast()
const router = useRouter()

const menuList = computed(() => {
  if (desktopMenu.value)
    return desktopMenu.value

  return [
    {
      name: 'Dashboard',
      route: '/',
    },
    {
      name: 'Tes Kerja',
      type: 'submenu',
      route: '/',
    },
    {
      name: 'Tryout',
      route: '/tryout-dashboard',
    },
    {
      name: 'Live Class',
      route: '/class',
    },
    {
      name: 'Drilling Soal',
      route: '/drilling-soal',
    },
    {
      name: 'Ebook',
      route: '/ebook',
    },
    {
      name: 'Pembayaran',
      route: '/payment',
    },
    {
      name: '-',
      type: 'divider',
      route: '/',
    },
    {
      name: 'Educreator Partner',
      route: '/upgrade-skill',
    },
  ]
})

function isActive(route) {
  return route === currentRoute.value.path
}

async function onLogout() {
  try {
    globalStore.$patch({ isLoading: true })

    const params = {
      email: authStore.user.email,
    }

    await Api.post(`${process.env.VUE_APP_API_URL}/multi-tenant/logout`, params, {
      headers: {
        Domain: appId.value,
      },
    })

    getActivePinia()._s.forEach((store) => {
      if (store.$id !== 'app')
        store.$reset()
    })

    toast.success('Sukses Logout', { position: 'top-center' })

    globalStore.$patch({ isLoading: false })

    router.replace('/login')
  }
  catch (err) {
    toast.error('Ooppss... Ada Kesalahan')

    globalStore.$patch({ isLoading: false })
  }
}

const productsModal = ref()
function showProducts() {
  productsModal.value.openModal()
}
</script>

<template>
  <aside class="jago-sidebar">
    <div class="max-h-[40px] mb-8">
      <img :src="logoExpand" class="h-full">
    </div>
    <template
      v-for="(item, idx) in menuList"
      :key="idx"
    >
      <span
        v-if="item.type && item.type === 'submenu'"
        class="jago-sidebar-submenu"
      >
        {{ item.name }}
      </span>
      <div
        v-else-if="item.type && item.type === 'divider'"
        class="my-5 bg-black-400 h-[1px] w-full"
      />
      <router-link
        v-else
        :to="item.route"
        class="jago-sidebar-menu"
        :class="isActive(item.route) ? 'active' : ''"
      >
        <h6>{{ item.name }}</h6>
      </router-link>
    </template>
    <div class="mt-auto w-full">
      <div
        class="jago-sidebar-menu text-primary"
        @click="showProducts"
      >
        Produk
      </div>
      <div
        class="jago-sidebar-menu text-danger"
        @click="onLogout"
      >
        Logout
      </div>
    </div>

    <ProductsModal ref="productsModal" />
  </aside>
</template>

<style lang="postcss">
.jago-sidebar {
  @apply fixed top-0 left-0 bg-white h-screen z-40 transition-all
  hidden md:flex w-[280px] py-6 px-4 flex-col items-center;
}

.jago-sidebar-menu {
  @apply text-left mb-2 w-full px-4 py-2 cursor-pointer text-body
  font-medium rounded-lg transition-all hover:bg-black-400;
}

.jago-sidebar-menu.active {
  @apply bg-primary-lighten text-secondary
}

.jago-sidebar-submenu {
  @apply w-full px-4 text-caption font-medium text-black-500 mb-2 mt-4;
}
</style>
