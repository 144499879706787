<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: null,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  cardClass: {
    type: String,
    default: null,
  },
  maxWidth: {
    type: String,
    default: null,
  },
})
const emits = defineEmits(['open', 'close', 'update:modelValue'])

const isOpen = ref(true)

const model = computed({
  get() {
    if (props.modelValue === null)
      return isOpen.value

    return props.modelValue
  },
  set(value) {
    if (props.modelValue === null)
      return isOpen.value = value

    return emits('update:modelValue', value)
  },
})

watch(model, (value) => {
  if (value)
    emits('open')
  else
    emits('close')
})

function toggle() {
  model.value = !model.value
}

function onBgClick() {
  if (!props.persistent)
    model.value = false
}

defineExpose({
  isOpen,
  toggle,
})
</script>

<template>
  <div>
    <div
      @click="model = true"
    >
      <slot name="activator" />
    </div>
    <Teleport to="body">
      <!-- backdrop -->
      <Transition name="fade-transition">
        <div
          v-if="model"
          class="sheet-bg w-full h-full"
        />
      </Transition>

      <!-- dialog -->
      <Transition name="dialog-transition" mode="out-in">
        <div
          v-if="model"
          class="fixed overflow-x-hidden inset-0 z-50 dialog-open"
          @click="onBgClick"
        >
          <div class="w-full h-full flex justify-center items-center" @click="onBgClick">
            <div
              :class="[
                props.cardClass
                  ? props.cardClass
                  : 'm-4 bg-white rounded-lg w-full max-w-[900px] h-auto max-h-[90vh] md:max-h-[80vh]',
                props.noPadding
                  ? ''
                  : 'p-4',
              ]"
              :style="props.maxWidth ? `max-width: ${props.maxWidth}` : ''"
              @click.stop
            >
              <slot />
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<style lang="postcss">
.sheet-bg {
  @apply fixed overflow-x-hidden inset-0 z-50;

  background: rgba(0, 0, 0, 0.4);
}
</style>

<style>
body:has(.dialog-open) {
  overflow: hidden;
}
</style>
