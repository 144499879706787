<script setup>
import { ref } from 'vue'
import { Icon } from '@iconify/vue'
import Modal from '../atoms/Modal.vue'
import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'

const appStore = useAppStore()
const authStore = useAuthStore()

const isOpen = ref(false)
function openModal() {
  isOpen.value = true
}
defineExpose({
  openModal,
})
</script>

<template>
  <Modal v-model="isOpen" max-width="500px" class="max-h-[75vh]">
    <div class="text-base font-semibold text-primary text-center mt-2 mb-6">
      Semua Produk Jagotes.id
    </div>
    <a
      v-for="product in appStore.otherProducts"
      :key="product.id"
      :href="`https://${product.domain}?q=${authStore.token}`"
      target="_blank"
      class="flex justify-between border rounded px-3 py-[10px] items-center hover:cursor-pointer mt-3"
    >
      <img :src="product.logo" :alt="`logo-product-${product.id}`" class="h-8 w-8">
      <div class="text-sm font-semibold text-black-700">
        {{ product.name }}
      </div>
      <Icon icon="mdi:chevron-right" class="text-primary text-[28px]" />
    </a>
  </Modal>
</template>
