import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/user'

export async function auth({ next, to }) {
  const authStore = useAuthStore()
  const userStore = useUserStore()

  if (!authStore.token) {
    const redirectRoute = to.fullPath
    return next(`/login?redirect=${redirectRoute}`)
  }

  if (!userStore.user) {
    await userStore.initUserData()

    if (userStore?.user?.province === null) {
      return next('/user-detail')
    }
  }

  return next()
}
