<script setup>
import { onMounted, ref } from 'vue'
import { Icon } from '@iconify/vue'
import moment from 'moment'
import Api from '@/utils/api'
import useAppData from '@/composables/useAppData'

const props = defineProps({
  selectedVoucher: {
    type: Number,
    default: null,
  },
  paymentPrice: {
    type: Number,
    default: null,
  },
  paymentMethod: {
    type: String,
    default: null,
  },
})

const emits = defineEmits(['onVoucherSelect'])

const { logoSquare } = useAppData()

const loading = ref(true)
const voucherList = ref([])

async function getVoucherList() {
  try {
    loading.value = true

    const { data } = await Api.get('/personal-voucher')

    voucherList.value = data.data

    loading.value = false
  }
  catch (err) {
    console.log(err)

    loading.value = false
  }
}

function onVoucherSelect(voucher) {
  emits('onVoucherSelect', voucher)
}

onMounted(() => {
  getVoucherList()
})
</script>

<template>
  <Icon
    v-if="loading"
    icon="svg-spinners:ring-resize"
    class="text-[28px] text-primary mx-auto my-8"
  />
  <div v-else class="flex flex-col gap-3">
    <div
      v-for="(item, index) in voucherList"
      :key="index"
      class="rounded border p-2.5 cursor-pointer relative"
      :class="{
        'bg-primary-lighten': item.id === props.selectedVoucher,
      }"
      @click="onVoucherSelect(item)"
    >
      <div class="flex justify-between items-start">
        <div>
          <h6 class="text-black-800 font-medium text-caption leading-1">
            {{ item.description }}
          </h6>
          <span class="text-small text-black-600">
            Minimal Pembelian {{ item.rules.minimum_transaction }}
          </span>
        </div>

        <img :src="logoSquare" class="w-8 h-8 object-contain">
      </div>

      <div class="flex justify-between items-end">
        <div>
          <span class="text-black-600 text-tiny mb-0.5">
            Berlaku Hingga
          </span>

          <div class="flex items-center gap-1">
            <Icon
              icon="ri:time-fill"
              class="text-danger text-[16px]"
            />
            <span class="text-small">
              {{ moment(item.expiry_at).format('DD/MM/YYYY HH.mm') }}
            </span>
          </div>
        </div>

        <h6 class="text-primary text-caption font-medium">
          Pakai
        </h6>
      </div>

      <div
        v-if="props.paymentPrice < item.rules.minimum_transaction"
        class="absolute h-full w-full bg-black bg-opacity-20 top-0 left-0 rounded flex p-2 items-end justify-end cursor-not-allowed"
        @click.stop
      >
        <div class="bg-danger-lighten text-danger text-small p-2 rounded">
          Belum Mencapai Minimal Pembelian
        </div>
      </div>
    </div>
  </div>
</template>
