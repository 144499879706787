import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useGlobalStore } from '@/store/global'
import Api from '@/utils/api'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    userProfile: null,
    stats: null,
  }),
  getters: {},
  actions: {
    async initUserData() {
      const globalStore = useGlobalStore()
      const authStore = useAuthStore()

      try {
        globalStore.$patch({ isLoading: true })

        const api = await Api.get('/user-data')
        this.user = api.data.user
        authStore.user = api.data.user

        globalStore.$patch({ isLoading: false })
      }
      catch (err) {
        console.log(err)
        globalStore.$patch({ isLoading: false })
      }
    },
  },
})
