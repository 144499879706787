<script setup>
import { Icon } from '@iconify/vue'
import { computed, onMounted, ref } from 'vue'
import useAppData from '@/composables/useAppData'
import Api from '@/utils/api'
import { rupiah } from '@/utils/helpers'

const emits = defineEmits(['onSelectMethod'])

const { logoSquare } = useAppData()

const groupTitle = {
  'convenience_store': 'Minimarket',
  'e-wallet': 'E-Wallet',
  'bank_transfer': 'Transfer Bank',
}

const loading = ref(false)
const paymentMethodList = ref([])

const groupedPaymentMethods = computed(() => {
  if (!paymentMethodList.value?.length)
    return []

  const groupedData = paymentMethodList.value.reduce((acc, item) => {
    const group = acc.find(g => g.group === item.group)
    if (group) {
      group.list.push(item)
    }
    else {
      acc.push({
        group: item.group,
        title: groupTitle[item.group] ?? '',
        list: [item],
      })
    }
    return acc
  }, [])

  return groupedData
})

function onSelectMethod(item) {
  emits('onSelectMethod', item)
}

async function getPaymentMethodList() {
  try {
    loading.value = true

    const { data } = await Api.get('/payment-channel/get-list')

    paymentMethodList.value = data

    loading.value = false
  }
  catch (err) {
    console.log(err)

    loading.value = false
  }
}

onMounted(() => {
  getPaymentMethodList()
})
</script>

<template>
  <Icon
    v-if="loading"
    icon="svg-spinners:ring-resize"
    class="text-[28px] text-primary mx-auto my-8"
  />

  <div v-else>
    <div
      v-for="(group, index) in groupedPaymentMethods"
      :key="index"
      class="flex flex-col gap-2 mb-4"
    >
      <h6 class="text-caption font-medium">
        {{ group.title }}
      </h6>
      <div
        v-for="(method, indexMethod) in group.list"
        :key="indexMethod"
        class="flex gap-3 border rounded p-2 items-center cursor-pointer"
        @click="onSelectMethod(method)"
      >
        <img
          class="w-8 h-8 object-contain rounded"
          :src="method.icon ?? logoSquare"
        >

        <div class="flex flex-col">
          <span class="text-caption font-medium">
            {{ method.name }}
          </span>
          <small class="text-small text-black-600">
            <template v-if="method.charge_type === 'FLAT'">
              Platform Fee : {{ rupiah(method.charge_amount) }}
            </template>
            <template v-else-if="method.charge_type === 'PERCENT'">
              Platform Fee : {{ method.charge_amount }}%
            </template>
          </small>
        </div>

        <Icon
          class="text-[28px] text-black-600 ml-auto"
          icon="ri:arrow-right-s-line"
        />
      </div>
    </div>
  </div>
</template>
