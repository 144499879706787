import { computed } from 'vue'
import { useAppStore } from '@/store/app'

function useAppData() {
  const appStore = useAppStore()

  const desktopMenu = computed(() => appStore?.appData?.desktop_menu)
  const mobileMenu = computed(() => appStore?.appData?.mobile_menu)
  const dashboardMenu = computed(() => appStore?.appData?.dashboard_menu)
  const logoExpand = computed(() => appStore?.appData?.logo_expand)
  const logoSquare = computed(() => appStore?.appData?.logo_square)
  const appColor = computed(() => appStore?.appData?.color)
  const appName = computed(() => appStore?.appData?.name ?? 'Jagotes.id')
  const testGroup = computed(() => appStore?.appData?.test_group)
  const instagramLink = computed(() => appStore?.appData?.instagram)
  const sidebarBanner = computed(() => appStore?.appData?.sidebar_banner)
  const loginComponent = computed(() => {
    if (appStore?.appData?.login_component && typeof appStore?.appData?.login_component === 'string')
      return JSON.parse(appStore?.appData?.login_component)

    return appStore?.appData?.login_component
  })
  const backgroundPendalamanTopik = computed(() => appStore?.appData?.background)

  const appId = computed(() => {
    let id = 'app.jagotes.id'

    const url = window?.location?.origin
    const subdomainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/
    const matches = url.match(subdomainRegex)
    const subdomain = matches ? matches[1] : null

    if (subdomain && !subdomain.includes('localhost')) {
      id = subdomain
    }
    else if (process.env.VUE_APP_ID) {
      id = process.env.VUE_APP_ID
    }

    return id
  })

  const otherProducts = computed(() => appStore.otherProducts)

  return {
    desktopMenu,
    mobileMenu,
    dashboardMenu,
    logoExpand,
    logoSquare,
    appColor,
    testGroup,
    appName,
    appId,
    instagramLink,
    sidebarBanner,
    loginComponent,
    backgroundPendalamanTopik,
    otherProducts,
  }
}

export default useAppData
