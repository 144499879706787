<script setup>
import { Icon } from '@iconify/vue'
import { useUserStore } from '@/store/user'

const userStore = useUserStore()
</script>

<template>
  <div v-if="userStore.userProfile?.whatsapp_cs_link" class="fixed bottom-20 right-4 md:bottom-6 md:right-6 z-30">
    <a :href="userStore.userProfile?.whatsapp_cs_link" target="_blank">
      <div class="flex items-center gap-1 shadow-lg border-2 border-black-400 px-2 py-1 md:px-3 md:py-2 rounded-t-xl rounded-bl-xl bg-white">
        <Icon
          icon="logos:whatsapp-icon"
          class="text-[18px]"
        />
        <span class="text-small font-medium text-black-600">
          Hubungi CS
        </span>
      </div>
    </a>
  </div>
</template>
