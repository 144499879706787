import { useAuthStore } from '@/store/auth'

export function guest({ next }) {
  const authStore = useAuthStore()

  if (authStore.token)
    return next('/')

  return next()
}
