import axios from 'axios'
import { useAuthStore } from '@/store/auth'
import useAppData from '@/composables/useAppData'

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_JAGOTES_URL,
  headers: {
    accept: 'application/json',
  },
})

Api.interceptors.request.use((config) => {
  const authStore = useAuthStore()
  const { appId } = useAppData()

  config.headers.Authorization = authStore.token && `Bearer ${authStore.token}`
  config.headers.Domain = appId.value

  return config
})

export default Api
