export function rupiah(number) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(number)
}

export function numberFormat(number) {
  return new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
  }).format(number)
}

export function increasingInterval(fn, timeout) {
  function next() {
    fn()
    setTimeout(next, timeout, timeout *= 60)
  }

  setTimeout(next, timeout)
}

export function convertToFormData(data) {
  const formData = new FormData()

  for (const key in data) {
    formData.append(key, data[key])
  }

  return formData
}

export function getIntegerTimeLeft(timeleft) {
  const splitString = timeleft.split(':')

  const hours = Number.parseInt(splitString[0])
  const minutes = Number.parseInt(splitString[1])
  const seconds = Number.parseInt(splitString[2])

  return {
    hours: Number.isNaN(hours) ? 0 : hours,
    minutes: Number.isNaN(minutes) ? 0 : minutes,
    seconds: Number.isNaN(seconds) ? 0 : seconds,
  }
}

export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some((toMatchItem) => {
    return toMatchItem.test(navigator.userAgent)
  })
}

export function getYoutubeID(str) {
  const regex = /youtu\.be\/([^?]+)/
  const match = str.match(regex)

  if (!match)
    return null

  return match[1]
}
