<script setup>
import { onMounted, ref } from 'vue'
import { Icon } from '@iconify/vue'
import { useStorage } from '@vueuse/core'
import Modal from '../atoms/Modal.vue'
import PaymentPopup from './PaymentPopup.vue'
import useAppData from '@/composables/useAppData'
import Api from '@/utils/api'

const { appId } = useAppData()

const paymentPopup = ref()
const isOpen = ref(false)
const popupData = ref({})
const lastUltimatePopup = useStorage('last-ultimate-popup')

async function fetchUltimatePopup() {
  if (lastUltimatePopup.value !== undefined) {
    const parsedDate = new Date(Date.parse(lastUltimatePopup.value))
    const diff = new Date() - parsedDate

    // check if difference is more less than 1 hour
    if (diff <= (1000 * 60 * 60))
      return
  }

  try {
    const { data } = await Api.get(
      `${process.env.VUE_APP_API_URL}/multi-tenant/ultimate-popup`,
      {
        headers: {
          Domain: appId.value,
        },
      },
    )

    popupData.value = data
    lastUltimatePopup.value = new Date()

    setTimeout(() => {
      isOpen.value = true
    }, 1000)
  }
  catch (err) {
    console.log(err)
  }
}

function onImgClick() {
  paymentPopup.value.show({
    product_id: popupData.value.data.product.id,
    product_name: popupData.value.data.product.master_name,
    price: popupData.value.data.product.price ?? popupData.value.data.product.sale_price,
    sale_price: popupData.value.data.product.sale_price,
  }, {
    step: popupData.value.data.step,
    voucher: popupData.value.data.voucher,
  })
}

onMounted(() => {
  fetchUltimatePopup()
})
</script>

<template>
  <Modal
    v-if="popupData.image"
    v-model="isOpen"
    no-padding
    card-class="w-auto m-4"
  >
    <div class="relative">
      <img
        :src="popupData.image"
        class="max-h-[75vh] rounded-lg cursor-pointer"
        @click="onImgClick"
      >

      <div class="bg-white rounded-full p-1 bg-opacity-50 absolute right-2 top-2">
        <Icon
          icon="ri:close-line"
          class="text-[20px] cursor-pointer text-white"
          @click="isOpen = false"
        />
      </div>
    </div>

    <PaymentPopup ref="paymentPopup" />
  </Modal>
</template>
