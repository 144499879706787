export function errorReload(error, retries = 1) {
  console.error(error)

  const errRetries = sessionStorage.getItem('errRetries')
  console.log('errorReload()', typeof errRetries, errRetries)
  if (typeof errRetries === 'string') {
    // we have tried before
    if (Number.parseInt(errRetries) >= retries) {
      // ah, give up
      console.log('errorReload() now give up')
      return
    }

    console.log('errorReload() now try again')
    sessionStorage.setItem('errRetries', String(Number.parseInt(errRetries) + 1))
  }
  else {
    // we have not tried before
    console.log('errorReload() try again')
    sessionStorage.setItem('errRetries', '1')
  }

  window.location.reload()
}
