<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'submit',
  },
  variant: {
    type: String,
    required: false,
  },
  disabled: Boolean,
})

let { variant } = props

const variantClasses = computed(() => {
  switch (variant) {
    case 'info':
      return variant = 'bg-indigo-500 border-transparent text-white hover:bg-indigo-600 focus:border-indigo-600 focus:ring-indigo-200 active:bg-indigo-500'
    case 'info-outline':
      return variant = 'text-indigo-500 hover:text-white border border-indigo-500 hover:bg-indigo-500 focus:outline-none focus:ring-indigo-200'
    case 'danger':
      return variant = 'bg-red-600 border-transparent text-white hover:bg-red-500 focus:border-red-700 focus:ring-red-200 active:bg-red-600'
    case 'danger-outline':
      return variant = 'text-red-600 hover:text-white border border-red-600 hover:bg-red-600 focus:outline-none focus:ring-red-200'
    case 'secondary':
      return variant = 'bg-white border-gray-300 text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:ring-blue-200 active:text-gray-800 active:bg-gray-50'
    case 'success':
      return variant = 'bg-[#227900] border-transparent text-white hover:bg-green-500 focus:border-green-700 focus:ring-green-300 active:bg-green-700'
    case 'success-outline':
      return variant = 'text-green-600 hover:text-white border border-green-600 hover:bg-green-600 focus:outline-none focus:ring-green-300'
    case 'warning':
      return variant = 'bg-[#FD8709] border-transparent text-black hover:bg-yellow-300 focus:border-yellow-500 focus:ring-yellow-200 active:bg-yellow-400'
    case 'warning-outline':
      return variant = 'text-yellow-400 hover:text-gray-800 border border-yellow-400 hover:bg-yellow-400 focus:outline-none focus:ring-yellow-200'
    case 'primary-outline':
      return variant = 'text-primary hover:text-white border border-primary hover:bg-primary focus:outline-none focus:ring-blue-300'
    case 'primary-cat-outline':
      return variant = 'text-[#007BFF] hover:text-white border border-[#007BFF] border-2 hover:bg-[#007BFF] focus:outline-none focus:ring-blue-300'
    case 'cat-button':
      return variant = 'text-white border-tranparent bg-[#007BFF] text-[14px]'
    case 'accent':
      return variant = 'bg-accent text-white border-transparent hover:bg-accent hover:bg-opacity-90 active:bg-accent focus:border-blue-900 focus:ring-blue-300'
    case 'accent-lighten':
      return variant = 'bg-accent-lighten text-accent border-transparent hover:bg-accent hover:text-white hover:hover:bg-opacity-90 active:bg-accent focus:border-blue-900 focus:ring-blue-300'
    case 'primary':
    default:
      return variant = 'bg-primary text-white border-transparent active:bg-primary focus:border-blue-900 focus:ring-blue-300'
  }
})
</script>

<template>
  <button
    :type="type"
    class="hover:brightness-90 inline-flex items-center justify-center px-2 py-1 border rounded-md focus:outline-none focus:ring disabled:opacity-25 transition"
    :class="[variantClasses]" :disabled="disabled"
  >
    <slot />
  </button>
</template>
