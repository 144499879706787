import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/middleware/auth'
import { guest } from '@/middleware/guest'
import useAppData from '@/composables/useAppData'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Dashboard/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Dashboard',
    },
  },
  {
    path: '/starter-pack',
    name: 'StarterPack',
    component: () => import('../views/Dashboard/StarterPackView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Starter Pack',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginView.vue'),
    meta: {
      middleware: guest,
      title: 'Login',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Auth/RegisterView.vue'),
    meta: {
      middleware: guest,
      title: 'Register',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/Auth/ResetPasswordView.vue'),
    meta: {
      middleware: guest,
      title: 'Reset Password',
    },
  },
  {
    path: '/user-detail',
    name: 'user-detail',
    component: () => import('../views/UserDetail/UserDetail.vue'),
    meta: {
      middleware: auth,
      title: 'User Detail',
    },
  },
  {
    path: '/ebook',
    name: 'ebook',
    component: () => import('../views/Ebook/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Ebook',
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/Payment/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Pembayaran',
    },
  },
  {
    path: '/payment-history',
    name: 'payment-history',
    component: () => import('../views/Payment/HistoryView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'History Pembayaran',
    },
  },
  {
    path: '/class',
    name: 'class',
    component: () => import('../views/Class/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Kelas',
    },
  },
  {
    path: '/class/:id',
    name: 'class detail',
    component: () => import('../views/Class/DetailClass.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Detail Kelas',
    },
  },
  {
    path: '/exercise',
    name: 'exercise',
    component: () => import('../views/Exercise/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Latihan',
    },
  },
  {
    path: '/review/:submissionId',
    name: 'Review',
    component: () => import('../views/TryoutDashboard/ReviewView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
    },
  },
  {
    path: '/review-subtest/:id/:submissionId',
    name: 'review-subtest',
    component: () => import('../views/TryoutDashboard/ReviewDetailView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
    },
  },
  {
    path: '/tryout-dashboard',
    name: 'tryout-dashboard',
    component: () => import('../views/TryoutDashboard/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Tryout',
    },
  },
  {
    path: '/tryout/utbk/start/:id',
    name: 'tryout-utbk-start',
    component: () => import('../views/UTBKTryout/StartView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
      transition: 'blank',
    },
  },
  {
    path: '/tryout/utbk/highlight/:id',
    name: 'tryout-utbk-highlight',
    component: () => import('../views/UTBKTryout/HighlightView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
      transition: 'blank',
    },
  },
  {
    path: '/tryout/utbk/intro/:id',
    name: 'tryout-utbk-intro',
    component: () => import('../views/UTBKTryout/IntroView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
      transition: 'blank',
    },
  },
  // {
  //   path: '/tryout/start/:id?',
  //   name: 'tryout-start',
  //   component: () => import('../views/Tryout/StartView.vue'),
  //   meta: {
  //     middleware: auth,
  //     title: 'Tryout',
  //   },
  // },
  // {
  //   path: '/tryout/highlight/:id',
  //   name: 'tryout-highlight',
  //   component: () => import('../views/Tryout/HighlightView.vue'),
  //   meta: {
  //     middleware: auth,
  //     title: 'Tryout',
  //   },
  // },
  // {
  //   path: '/tryout/question-finish',
  //   name: 'tryout-question-finish',
  //   component: () => import('../views/Tryout/QuestionFinishView.vue'),
  //   meta: {
  //     middleware: auth,
  //     title: 'Tryout',
  //   },
  // },
  // {
  //   path: '/tryout/intro/:id',
  //   name: 'tryout-intro',
  //   component: () => import('../views/Tryout/IntroView.vue'),
  //   meta: {
  //     middleware: auth,
  //     title: 'Tryout',
  //   },
  // },
  // {
  //   path: '/tryout/:utbkId/:subtestId/:questionId',
  //   name: 'tryout',
  //   component: () => import('../views/Tryout/QuestionView.vue'),
  //   meta: {
  //     middleware: auth,
  //     title: 'Tryout',
  //   },
  // },
  {
    path: '/tryout/cat/intro/:id',
    name: 'tryout-cat-intro',
    component: () => import('../views/CATTryout/IntroView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
    },
  },
  {
    path: '/tryout/cat/:utbkId/:subtestId/:questionId',
    name: 'tryout-cat',
    component: () => import('../views/CATTryout/QuestionView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
      transition: 'blank',
    },
  },
  {
    path: '/tryout/cat/question-finish',
    name: 'tryoutcat-question-finish',
    component: () => import('../views/CATTryout/QuestionFinishView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
    },
  },
  {
    path: '/tryout/utbk/:utbkId/:subtestId/:questionId',
    name: 'tryout-utbk',
    component: () => import('../views/UTBKTryout/QuestionView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
      transition: 'blank',
    },
  },
  {
    path: '/tryout/utbk/question-finish',
    name: 'tryoututbk-question-finish',
    component: () => import('../views/UTBKTryout/QuestionFinishView.vue'),
    meta: {
      middleware: auth,
      title: 'Tryout',
    },
  },
  {
    path: '/tryout/leaderboard/:id',
    name: 'tryout-leaderboard',
    component: () => import('../views/Tryout/LeaderboardView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Leaderboard',
    },
  },
  {
    path: '/quiz-review-leaderboard',
    name: 'quiz-review-leaderboard',
    component: () => import('../views/Quiz/LeaderboardView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },
  {
    path: '/quiz-review/:id',
    name: 'quiz-review',
    component: () => import('../views/Quiz/ReviewView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },
  {
    path: '/quiz/intro/:id',
    name: 'quiz-intro',
    component: () => import('../views/Quiz/StartView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },
  {
    path: '/quiz/:id',
    name: 'quiz',
    component: () => import('../views/Quiz/QuestionView.vue'),
    meta: {
      middleware: auth,
      meta: {
        transition: 'slide-right',
        title: 'Quiz',
      },
    },
  },
  {
    path: '/live-quiz/register',
    name: 'live-quiz-register',
    component: () => import('../views/Quiz/LiveRegisterView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },
  {
    path: '/live-quiz/intro',
    name: 'live-quiz-intro',
    component: () => import('../views/Quiz/LiveStartView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },
  {
    path: '/live-quiz/:id',
    name: 'live-quiz',
    component: () => import('../views/Quiz/LiveQuestionView.vue'),
    meta: {
      middleware: auth,
      title: 'Quiz',
    },
  },

  {
    path: '/upgrade-skill',
    component: () => import('../views/UpgradeSkill/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Educreator Partner',
    },
  },
  {
    path: '/upgrade-skill/:id',
    component: () => import('../views/UpgradeSkill/PersonalRoomView.vue'),
    meta: {
      middleware: auth,
      title: 'Educreator Partner',
    },
  },
  {
    path: '/upgrade-skill/:id/:courseId',
    component: () => import('../views/UpgradeSkill/DetailCourseView.vue'),
    meta: {
      middleware: auth,
      title: 'Educreator Partner',
    },
  },
  {
    path: '/drilling-soal',
    name: 'List Drilling Soal',
    component: () => import('../views/DrillingSoal/DrillingSoal.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Drilling Soal',
    },
  },
  {
    path: '/drilling-soal/:id/:questionId',
    name: 'Drilling Soal',
    component: () => import('../views/DrillingSoal/QuestionView.vue'),
    meta: {
      middleware: auth,
      title: 'Drilling Soal',
    },
  },
  {
    path: '/lulus-bareng',
    name: 'Lulus Bareng',
    component: () => import('../views/LulusBareng/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Lulus Bareng',
    },
  },
  // TODO: add roomId
  {
    path: '/lulus-bareng/waiting-room',
    name: 'Lulus Bareng Waiting Room',
    component: () => import('../views/LulusBareng/WaitingRoomView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Lulus Bareng',
    },
  },
  {
    path: '/lulus-bareng/public-room/:id',
    name: 'Lulus Bareng Public Room',
    component: () => import('../views/LulusBareng/PublicRoom.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Lulus Bareng',
    },
  },
  {
    path: '/lulus-bareng/public-room/submission/:id/:roundId',
    name: 'Lulus Bareng Submission',
    component: () => import('../views/LulusBareng/QuestionView.vue'),
    meta: {
      middleware: auth,
      title: 'Lulus Bareng',
    },
  },
  {
    path: '/content',
    name: 'Content',
    component: () => import('../views/Content/ContentView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Konten',
    },
  },
  {
    path: '/video',
    name: 'ContentVideo',
    component: () => import('../views/Content/ContentVideo.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Video',
    },
  },
  {
    path: '/preview/:layout/:question_id',
    name: 'QuestionPreview',
    component: () => import('../views/CATTryout/PreviewQuestion.vue'),
    meta: {
      title: 'Question Preview',
    },
  },
  {
    path: '/pendalaman-topik',
    name: 'Pendalaman Topik',
    component: () => import('../views/PendalamanTopik/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Pendalaman Topik',
    },
  },
  {
    path: '/pendalaman-topik/session/:questionId',
    name: 'Pendalaman Topik Question View',
    component: () => import('../views/PendalamanTopik/QuestionView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Pendalaman Topik',
    },
  },
  {
    path: '/pendalaman-topik/result',
    name: 'Pendalaman Topik Result Page',
    component: () => import('../views/PendalamanTopik/ResultView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Pendalaman Topik',
    },
  },
  {
    path: '/discussion/:id/:submissionId/:questionId',
    name: 'Diskusi',
    component: () => import('../views/DiscussionQuestion/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Diskusi',
    },
  },
  {
    path: '/simulasi-nasional',
    name: 'Simulasi Nasional',
    component: () => import('../views/SimulasiNasional/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Simulasi Nasional',
    },
  },
  {
    path: '/live-event',
    component: () => import('../views/LiveEvent/IndexView.vue'),
    meta: {
      middleware: auth,
      layout: 'dashboard',
      title: 'Live Event',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes,
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware)
    return context.next

  return (...parameters) => {
    context.next(...parameters)

    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  const { appName } = useAppData()

  if (to.meta.title) {
    document.title = to.meta.title ? `${to.meta.title} | ${appName.value}` : appName.value
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
